import { Button, Divider, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputAnswer, Question } from "../../Integration/models";
import { CustomWidthTooltip } from "../../styles/Customtooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';

interface props {

    question: Question | undefined
    setAnswers: React.Dispatch<React.SetStateAction<InputAnswer[]>>;
    answers: InputAnswer[]
    currentLanguage: string
    preliminary: boolean
    isGreen: boolean;
    scope: string;
    scopeType: string
    
}
enum Tipo {
    S1 = "scopeTitle1",
    M1 = "scopeTitle2",
    F1 = "scopeTitle3",
    R1 = "scopeTitle4",
    E1 = "scopeTitle5",
    E2 = "scopeTitle6",
}

function convertStringToTipo(input: string): string  {
    switch (input) {
        case "S1":
            return Tipo.S1;
        case "M1":
            return Tipo.M1;
        case "F1":
            return Tipo.F1;
        case "R1":
            return Tipo.R1;
        case "E1":
            return Tipo.E1;
        case "E2":
            return Tipo.E2;
        default:
            return " ";
    }
}
const YesNoQuestion: React.FC<props> = ({ question, setAnswers, answers, currentLanguage, preliminary, isGreen, scope, scopeType }) => {

    const [option, setOption] = useState<string>(() => {
        const flag = answers.some(item => item.questionId == question?.id)
        if (flag) {
            let val = answers.find(item => item.questionId == question?.id)
            if (val?.yesOrNoAnswer == false) return "NO"
            else return "SI"
        }
        else
            return ""
    });
    const { i18n, t } = useTranslation();
    const input = question?.inputs?.find(item => item);

    const handleOption = (option: string) => {

        let yesOrNoAnswer = false

        if (option === "SI") {
            yesOrNoAnswer = true;
        } else if (option === "NO") {
            yesOrNoAnswer = false;
        }


        const e: InputAnswer = {
            inputId: input?.id,
            questionId: question?.id,
            yesOrNoAnswer: yesOrNoAnswer,
            isGreen: isGreen
        };

        if (!answers.some(item => item.questionId == question?.id)) setAnswers([...answers, e])
        else {
            let list = answers.filter(item => item.questionId != question?.id)
            setAnswers([...list, e])
        }
        setOption(option);
    };

    return (<>

        <Paper
            square
            elevation={2}
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                pl: 2,
                bgcolor: 'white',
                paddingBottom: "1rem"
            }}
        >
            {isGreen ? (<>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "-0.5vh", justifyContent: "center", padding: "0 10%", gap: "0.2vw" }}>
                    <h2 style={{ color: "black", fontSize: "1vw", textAlign: "center", lineHeight: "1.3" }}>
                        <span>
                            <CustomWidthTooltip title={t("infoGreen")} style={{ width: "0.1vw" }}>
                                <Button variant="info" style={{ marginBottom: '0.5vw', padding: 0, height: '1vw' }}>
                                    <EnergySavingsLeafOutlinedIcon style={{ width: "1.5vw", color:"green" }} />
                                </Button>
                            </CustomWidthTooltip>
                        </span>
                        {currentLanguage === "en" ? question?.titleEng : question?.titleIta}
                    </h2>

                </div>
            </>) : (
                <>
                    <h2 style={{ color: "black", fontSize: "1vw", textAlign: "center", gap: "2rem", marginLeft: "1rem" }}>
                        {currentLanguage === "en" ? question?.titleEng : question?.titleIta}
                    </h2>
                </>
            )}
            

            <p style={{ color: "black", fontSize: "0.8vw", fontStyle: "italic", marginTop: "-2vh", alignContent: "center" }} >{currentLanguage === "en" ? question?.subtitleEng : question?.subtitleIta}</p>
            <Grid
                item
                direction="row"
                display='flex'
                justifyContent="flex-start"
                alignItems="center"
                gap="0.5vw"

            >
                <Button
                    variant='navigation'
                    onClick={() => handleOption("SI")}
                    style={{
                        color: option === "SI" ? 'white' : '#007CC1',
                        backgroundColor: option === "SI" ? '#007CC1' : 'white'
                    }}
                >
                    {t("yes")}
                </Button>
                <Button
                    variant='navigation'
                    onClick={() => handleOption("NO")}
                    style={{
                        color: option === "NO" ? 'white' : '#007CC1',
                        backgroundColor: option === "NO" ? '#007CC1' : 'white'
                    }}
                >
                    NO
                </Button>
            </Grid>
        </Paper>
    </>
    )

}

export default YesNoQuestion