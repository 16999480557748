import React from 'react';
import { Pie } from 'react-chartjs-2';

interface PieChart2Props {
    scope1: number;
    scope2: number;

}

const BigPieChart: React.FC<PieChart2Props> = ({ scope1, scope2 }) => {

    const data = {
        //labels: ['Scope1 = 120 Ton CO2', 'Scope2[Ton CO2]'],
        datasets: [
            {

                data: [scope1, scope2],
                backgroundColor: [
                    "#007bff",
                    '#16497e',
                ],
                borderWidth: 3,
            },
        ],

    };

    const options = {
        cutout: '50%',
    };

    return (
        <div style={{ position: "relative" }}>
            <Pie data={data} options={options} />
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                }}
            >
                <p style={{fontSize:"1.5vw"}}><strong>{(scope1 + scope2).toFixed(1)}</strong></p>
                <p style={{fontSize:"1.2vw", marginTop:"-1vh"}}>tC02eq</p>
            </div>
        </div>
    );
};

export default BigPieChart
