import React, { useEffect, useState } from "react";
import {
  Question,
  Input,
  ScopeQuestionaire,
  InputAnswer,
  Answers,
} from "../../Integration/models";
import { Grid, Button, Divider, Paper, ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { createMyTheme } from "../../styles/theme";
import { BorderLinearProgress } from "../../styles/LinearProgress";
import QuestionsController from "../Controllers/QuestionsController";
import * as client from "../../Integration/api";
import { ClipLoader } from "react-spinners";
import { Configuration } from "../../Integration";
import CustomizedSteppers from "../../styles/CustomStepper";

interface props {
  completedSteps: number;
  setCompletedSteps: React.Dispatch<React.SetStateAction<number>>;
}

const E1: React.FC<props> = ({ completedSteps, setCompletedSteps }) => {
  const theme = createMyTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [scopeQuestionaire, setScopeQuestionaire] =
    React.useState<ScopeQuestionaire>();
  const [questions, setQuestions] = React.useState<Question[]>();
  const [questionsGreen, setQuestionsGreen] = React.useState<Question[]>();
  const [answers, setAnswers] = React.useState<InputAnswer[]>([]);
  const [preliminaryQuestions, setPreliminaryQuestions] = React.useState<
    Question[] | undefined
  >();
  const [warning, setWarning] = React.useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const token = sessionStorage.getItem("sessionToken");
  if (completedSteps < 4) setCompletedSteps(4);

  useEffect(() => {
    const conf: Configuration = {
      accessToken: token ? token : "",
      baseOptions: {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      },
    };
    const clientAPI = new client.DefaultApi(conf);

    if (loading) {
      clientAPI
        .getQuestion("E1")
        .then((res) => {
          setScopeQuestionaire(res.data);
          setPreliminaryQuestions(res.data.preliminaryQuestion);
          setQuestions(res.data.questions ? res.data.questions : []);
          setQuestionsGreen(
            res.data.questionsGreen ? res.data.questionsGreen : []
          );
          setAnswers(res.data.inputAnswers ? res.data.inputAnswers : []);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          if (error.response && error.response.status === 403) {
            setWarning("session Expired");
            setTimeout(() => {
              navigate("/openES");
            }, 1000);
          }
        });
    }
  }, [loading]);

  const handleContinue = (path: string) => {
    const conf: Configuration = {
      accessToken: token ? token : "",
      baseOptions: {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      },
    };
    const clientAPI = new client.DefaultApi(conf);
    if (answers.length == 0) {
      setWarning(t("warning1"));
      return;
    }
    if (
      questionsGreen &&
      answers.filter((item) => item.isGreen == true).length <
        questionsGreen?.length
    ) {
      setWarning(t("warning11"));
      return;
    }

    if (answers.length == 1 && answers.at(0)?.isPertinent == true) {
      setWarning(t("warning1"));
      return;
    }

    let varPrel1 = answers.find(
      (item) =>
        item.questionId ==
        preliminaryQuestions?.find((item) => item.questionType == 0)?.id
    );
    let varPrel2 = answers.find(
      (item) =>
        item.questionId ==
        preliminaryQuestions?.find((item) => item.questionType == 1)?.id
    );
    if (varPrel1?.isPertinent == false || varPrel2?.isRelevant == false) {
      const Ans: Answers = {
        scopeType: "E1",
        inputAnswers: answers,
      };

      setLoading(true);
      clientAPI
        .postScopeCategory(Ans)
        .then((res) => {
          setLoading(false);
          navigate(path);
        })
        .catch((error) => {
          console.error(error);
          if (error.response && error.response.status === 403) {
            setWarning("session Expired");
            setTimeout(() => {
              navigate("/openES");
            }, 1000);
          }
        });
    } else {
      //if (questions && !answers.some(item => item.questionId == questions[0].id)) {
      //    setWarning(t("warning9"))
      //    return
      //}
      //else {
      const Ans: Answers = {
        scopeType: "E1",
        inputAnswers: answers,
      };
      setLoading(true);
      clientAPI
        .postScopeCategory(Ans)
        .then((res) => {
          setLoading(false);
          navigate(path);
        })
        .catch((error) => {
          console.error(error);
          if (error.response && error.response.status === 403) {
            setWarning("session Expired");
            setTimeout(() => {
              navigate("/openES");
            }, 1000);
          }
        });
      //}
    }
  };

  const handleBack = () => {
    navigate(`/scope1-R1`);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        direction="column"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap="1vh"
        style={{ background: "#F5F5F5", minHeight: "93vh" }}
      >
        <Grid item width="70vw" style={{ marginTop: "14vh" }}>
          <CustomizedSteppers
            step={4}
            completed={completedSteps}
            handleContinue={handleContinue}
          />
        </Grid>
        {loading ? (
          <ClipLoader size={150} color={"#007BFF"} loading={loading} />
        ) : (
          <>
            <QuestionsController
              questions={questions}
              answers={answers}
              setAnswers={setAnswers}
              scope="Scope 2"
              preliminaryQuestions={preliminaryQuestions}
              warning={warning}
              setWarning={setWarning}
              scopeType="E1"
              questionsGreen={questionsGreen}
            />
            <Grid
              item
              style={{ width: "80vw", marginBottom: "5vh", marginTop: "1vh" }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="navigation"
                  onClick={(e) => handleBack()}
                  style={{ width: "7vw" }}
                >
                  {t("back")}
                </Button>
                <Button
                  onClick={(e) => handleContinue("/scope2-E2")}
                  variant="navigation"
                  style={{ width: "7vw" }}
                >
                  {t("continue")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default E1;
