import React, { useEffect, useState } from "react";
import { Grid, Button, Divider, Paper, Box, ThemeProvider, Modal, Collapse, Alert, IconButton, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Question, Input, ScopeQuestionaire, InputAnswer } from "../../Integration/models";
import CloseIcon from '@mui/icons-material/Close';
import Preliminary from "../Questions/Preliminary";
import FuelQuestionController from "./FuelQuestionController";
import YesNoQuestion from "../Questions/YesNoQuestion";
import HeatCooling from "../Questions/HeatCooling";
import CountryQuestionController from "./CountryQuestionController";
import { ModalComponent } from "../Components/ModalComponent";
import HeadBox from "../Questions/HeadBox";


interface props {

    scope: string,
    questions: Question[] | undefined,
    questionsGreen: Question[] | undefined,
    answers: InputAnswer[],
    setAnswers: React.Dispatch<React.SetStateAction<InputAnswer[]>>,
    preliminaryQuestions: Question[] | undefined,
    warning: string,
    setWarning: React.Dispatch<React.SetStateAction<string>>,
    scopeType: string
}


const QuestionsController: React.FC<props> = ({ scope, answers, setAnswers, preliminaryQuestions, questions, warning, setWarning, scopeType, questionsGreen }) => {

    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language)
    const [open, setOpen] = React.useState(false);
    const [relevantFlag, setRelevantFlag] = React.useState<string>(() => {
        if (preliminaryQuestions?.length == 2) {
            const flag = answers.some(item => item.questionId == preliminaryQuestions?.find(item => item.questionType == 1)?.id)
            if (flag) {
                let val = answers.find(item => item.questionId == preliminaryQuestions?.find(item => item.questionType == 1)?.id)
                if (val?.isRelevant == false) return "NO"
                else return "SI"

            }
            else
                return ""
        }
        else {
            return ""
        }
    });
    const [pertinentFlag, setPertinentFlag] = React.useState<string>(() => {
        const flag = answers.some(item => item.questionId == preliminaryQuestions?.find(item => item.questionType == 0)?.id)
        if (flag) {
            let val = answers.find(item => item.questionId == preliminaryQuestions?.find(item => item.questionType == 0)?.id)
            if (val?.isPertinent == false) return "NO"
            else return "SI"
        }
        else
            return ""
    });
    useEffect(() => {
        setCurrentLanguage(i18n.language)
    }, [i18n.language]);


    useEffect(() => {

        if (warning !== "")
            setOpen(true)

    }, [warning]);


    return (
        <>
            <>
                <Grid item style={{ marginTop: "1vh", width: "80vw" }}>
                    <HeadBox scopeType={scopeType} currentLanguage={currentLanguage} textEng={preliminaryQuestions?.find(item => item.questionType == 0)?.explanationEng} textIta={preliminaryQuestions?.find(item => item.questionType == 0)?.explanationIta} ></HeadBox>
                </Grid>
                {questionsGreen?.map((question, index) => (
                    <Grid item style={{ width: "80vw", marginTop: "2vh" }}>
                        {question.questionType == 3 && (<YesNoQuestion key={question.id} setAnswers={setAnswers} answers={answers} question={question} currentLanguage={currentLanguage} preliminary={false} isGreen={true} scope={scope} scopeType={scopeType} />)}
                    </Grid>
                ))}
            </>

            {preliminaryQuestions && (
                <>
                    <Grid item style={{ marginTop: "1vh", width: "80vw" }}>
                        <Preliminary question={preliminaryQuestions.find(item => item.questionType == 0)} viewFlag={pertinentFlag} answers={answers} setAnswers={setAnswers} scope={scope} currentLanguage={currentLanguage} pertinentFlag={pertinentFlag} setPertinentFlag={setPertinentFlag} relevantFlag={relevantFlag} setRelevantFlag={setRelevantFlag} scopeTitle={questionsGreen?.length == 0 ? true : false} scopeType={scopeType} setWarning={setWarning} />
                    </Grid>
                    {pertinentFlag == "SI" && (preliminaryQuestions.length == 2) && (
                        <Grid item style={{ marginTop: "1vh", width: "80vw" }}>
                            <Preliminary question={preliminaryQuestions.find(item => item.questionType == 1)} viewFlag={relevantFlag} answers={answers} setAnswers={setAnswers} scope={scope} currentLanguage={currentLanguage} pertinentFlag={pertinentFlag} setPertinentFlag={setPertinentFlag} relevantFlag={relevantFlag} setRelevantFlag={setRelevantFlag} scopeTitle={false} scopeType={scopeType} setWarning={setWarning} />
                        </Grid>
                    )}
                </>
            )
            }
            <ModalComponent warning={warning} setWarning={setWarning} open={open} setOpen={setOpen} />
            {((pertinentFlag === "SI" && relevantFlag === "SI") || (pertinentFlag === "SI" && preliminaryQuestions?.length == 1)) && (
                <>
                    {questions?.map((question, index) => (
                        <Grid item style={{ width: "80vw", marginTop: "2vh" }}>
                            {question.questionType == 2 && (<FuelQuestionController key={question.id} inputs={question.inputs} setAnswers={setAnswers} answers={answers} question={question} setWarning={setWarning} currentLanguage={currentLanguage} scopeType={scopeType} />)}
                            {question.questionType == 3 && (<YesNoQuestion key={question.id} setAnswers={setAnswers} answers={answers} question={question} currentLanguage={currentLanguage} preliminary={preliminaryQuestions ? true : false} isGreen={false} scope={scope} scopeType={scopeType} />)}
                            {question.questionType == 4 && (<CountryQuestionController key={question.id} inputs={question.inputs} setAnswers={setAnswers} answers={answers} question={question} currentLanguage={currentLanguage} setWarning={setWarning} />)}
                            {question.questionType == 5 && (<HeatCooling key={question.id} inputs={question.inputs} setAnswers={setAnswers} answers={answers} question={question} currentLanguage={currentLanguage} setWarning={setWarning} />)}
                        </Grid>
                    ))}
                </>
            )}
        </>

    )
}

export default QuestionsController