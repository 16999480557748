
import { LANGUAGES } from "../../costants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, FormControl, InputLabel, makeStyles, Theme, createStyles, Avatar, Button, createMuiTheme } from "@mui/material";
import logo from "../../images/logos/open-es.png"
import england from '../../images/flags/england.png';
import italy from '../../images/flags/italy.png';
import { CustomSelect } from "../../styles/CustomSelect";
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import citlogo from "../../images/logos/CITlogo.png"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link, useLocation } from 'react-router-dom';
import { OPENES_URL, OPENES_URL_PREPROD } from "../../costants";
import '../../App.css'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '5px 13px 5px 6px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      margin: 0
    },
  },
}));




const isActive = ({ isActive }: any) => `link ${isActive ? "active" : ""}`;

export const Menu: React.FC = ({ }) => {

  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const location = window.location.href


  const onChangeLang = (option: string) => {
    setSelectedLanguage(option);
    i18n.changeLanguage(option);
  };

  const redirectToAssistenza = () => {
    window.open('/assistenza', '_blank');
  };

  const redirectToOpenes = () => {

    (location.includes("dev") || location.includes("preprod") || location.includes("localhost")) ? window.location.href = OPENES_URL_PREPROD : window.location.href = OPENES_URL
  }

  return (
    <header style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      background: "linear-gradient(180deg, #007CC1 0%, #015F9F 100%)",
      zIndex: 100, // Assicurati che l'header sia sopra a tutti gli altri elementi
      height: "12vh"

    }}>
      <div style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        padding: "1rem",
        justifyContent: "space-between"

      }}>


        { <div style={{ justifyContent: "flex-start", justifyItems: "flex-start" }} onClick={redirectToOpenes}>
          <img src={logo} alt="Logo Center" style={{ width: '8vw' }} />
        </div> }

        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)", height: "12vh", justifyContent: "center" }}>
          {/* <img src={citlogo} width="6%" >
          </img> */}
          <div style={{ fontWeight: "bold", color: "white", fontSize: "1.5vw" }}>Carbon Intelligence Tool</div>
        </div>
        <div style={{ display: "flex", gap: "1vw", alignItems: "center", height: "7vh" }}>
          <Button onClick={redirectToAssistenza} variant="navigation" style={{
            color: "#015F9F",
            borderColor: "#FFD600",
            background: "#FFD600",
            fontWeight: 'bold',
            fontSize: '0.9vw',
            height: "6.2vh",

          }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.2vw", minWidth: "3vw", justifyContent: "center", color: "#007CC1" }}>
              <InfoOutlinedIcon style={{ color: "#007CC1" }}></InfoOutlinedIcon>
              <p>Help</p>
            </div>
          </Button>

          <FormControl style={{ marginTop: "-1vh" }}>
            <InputLabel id="language-select-label"></InputLabel>
            <CustomSelect
              labelId="language-select-label"
              id="language-select"
              defaultValue={"it"}
              value={selectedLanguage}
              input={<BootstrapInput />}

            >
              {LANGUAGES.map((language) => (
                <MenuItem key={language.code} value={language.code} onClick={() => onChangeLang(language.code)} >

                  <img alt={language.label} src={language.code == "en" ? england : italy} style={{ borderRadius: 0, objectFit: "contain", background: "transparent", height: "3.4vh", width: "2.2vw", marginTop: "0.5vh" }} />

                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>

        </div>
      </div>
    </header>
  );


};

