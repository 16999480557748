import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { useTranslation } from 'react-i18next';
import { BsFuelPumpFill } from 'react-icons/bs';
import { FaCarSide } from 'react-icons/fa';
import { FaFireExtinguisher } from 'react-icons/fa';
import { MdOilBarrel } from "react-icons/md";
import { BsSnow2 } from 'react-icons/bs';
import { MdElectricalServices } from 'react-icons/md';
import { BsFire } from 'react-icons/bs';
import { FaAddressBook } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface props {
    step: number,
    completed: number
    handleContinue: (path: string) => void
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(95deg, rgb(0, 53, 128) 0%, rgb(0, 92, 171) 50%, rgb(2, 117, 216) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(95deg, rgb(0, 53, 128) 0%, rgb(0, 92, 171) 50%, rgb(2, 117, 216) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 20,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center', // Contorno spesso, blu per lo step attivo, verde per gli step completati
    boxSizing: 'content-box', // Imposta il modello del box-sizing per includere il bordo nel calcolo delle dimensioni
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.1)', // Aumenta leggermente le dimensioni quando il mouse è sopra l'icona
    },
    ...(ownerState.active && {
        color: 'rgb(0, 53, 128)',
        border: `6px solid ${ownerState.active ? '#fac906' : '#2196f3'}`,
        backgroundColor: '#fff', // Sfondo bianco per ogni passo
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        '& img': {
            filter: 'brightness(0) invert(1)', // Immagine blu per lo step attivo
        },
    }),
    ...(ownerState.completed && {
        color: 'rgb(0, 53, 128)',
        border: `6px solid ${ownerState.active ? '#fac906' : '#2196f3'}`,
        backgroundColor: '#fff', // Sfondo bianco per ogni passo
        '& img': {
            filter: 'brightness(0) invert(1)', // Immagine blu per gli step completati
        },
    }),
}));


function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <MdOilBarrel />,
        2: <FaCarSide />,
        3: <FaFireExtinguisher />,
        4: <BsSnow2 />,
        5: <MdElectricalServices />,
        6: <BsFire />,
        7: <FaAddressBook />,

    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}



const CustomizedSteppers: React.FC<props> = ({ step, completed, handleContinue }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();


    const handleStepClick = (index: number) => {
        if (index <= completed) {
            let path = ""
            switch (index) {
                case 0: path = "/scope1-S1"; break;
                case 1: path = "/scope1-M1"; break;
                case 2: path = "/scope1-F1"; break;
                case 3: path = "/scope1-R1"; break;
                case 4: path = "/scope2-E1"; break;
                case 5: path = "/scope2-E2"; break;
                case 6: path = "/final"; break;
            }
            handleContinue(path)
        }
    };

    const steps = [t("stepperLabel1"), t("stepperLabel2"), t("stepperLabel3"), t("stepperLabel4"), t("stepperLabel5"), t("stepperLabel6"), t("stepperLabel7")];
    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel connector={<ColorlibConnector />}>
                {steps.map((label, index) => {
                    const complete = index <= completed;
                    const active = index == step;
                    return (
                        <Step key={label} completed={complete} active={active} onClick={() => handleStepClick(index)}>
                            <StepLabel StepIconComponent={() => <ColorlibStepIcon active={active} completed={complete} icon={index + 1} />}>
                               
                                    {
                                        label === "Corporate carbon footprint"  ? (
                                            <>
                                            <p>{label}</p>
                                            </>
                                          
                                        ) : (
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent:"flex-start",justifyItems:"start", marginTop:"-2vh"}}>
                                                <p>{label.split(" ")[0] + label.split(" ")[1]}</p>
                                                <p style={{marginTop:"-2vh"}}>{label.split(" ")[2]} {label.split(" ")[3]}</p> 
                                            </div>
                                        )
                                    }
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Stack>
    );
}

export default CustomizedSteppers