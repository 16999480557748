/* tslint:disable */
/* eslint-disable */
/**
 * Cit OpenES
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Answers } from '../models';
import { CompanyAnagrafica } from '../models';
import { CompanyData } from '../models';
import { DeleteRequest } from '../models';
import { Report } from '../models';
import { RevenueResponse } from '../models';
import { ScopeQuestionaire } from '../models';
import { SigninwithtokenBody } from '../models';
import { SubClusterRequest } from '../models';
import { SubClusterResponse } from '../models';
import { Token } from '../models';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteRequest} [body] Delete the company data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTheCompanyRecords: async (body?: DeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deleteCompanyRecords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers && localVarRequestOptions.headers['Content-Type'] === 'application/json');
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
         * Given all the answer sent to the backend generate the final report with all the emissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/generateReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/getProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
        * download for a year specified
        * @param {string} year
        * @param {string} language  
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        downloadReportPDF: async (year: string, language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined || language === undefined || language === null) {
                throw new RequiredError('year/language', 'Required parameter year was null or undefined when calling download.');
            }
            const localVarPath = `/api/getpdf/{year}/{language}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
          

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
        * download manuale for a language specified
        * @param {string} language 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        downloadManuale: async (language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            if (language === undefined || language === null) {
                throw new RequiredError('language', 'Required parameter year was null or undefined when calling download.');
            }
            const localVarPath = `/api/downloadManuale/{language}`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
          

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
        * download manuale for a language specified
        * @param {string} language 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        downloadQuestionario: async (language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            if (language === undefined || language === null) {
                throw new RequiredError('language', 'Required parameter year was null or undefined when calling download.');
            }
            const localVarPath = `/api/downloadQuestionario/{language}`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
          

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the question for the given scope
         * @param {string} scopeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestion: async (scopeType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scopeType' is not null or undefined
            if (scopeType === null || scopeType === undefined) {
                throw new RequiredError('scopeType', 'Required parameter scopeType was null or undefined when calling getQuestion.');
            }
            const localVarPath = `/api/getQuestion/{scopeType}`
                .replace(`{${"scopeType"}}`, encodeURIComponent(String(scopeType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the report for the given year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportForYear: async (reportYear: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            if (reportYear === null || reportYear === undefined) {
                throw new RequiredError('reportYear', 'Required parameter reportYear was null or undefined when calling getReport.');
            }
            const localVarPath = `/api/getReport/{reportYear}`
                .replace(`{${"reportYear"}}`, encodeURIComponent(String(reportYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            // const localVarPath = `/api/getReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savereportForm: async (file?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
          
            const localVarPath = `/api/savereport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubClusters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/getSubClusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the token for the current questio
         * @param {SigninwithtokenBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (body?: SigninwithtokenBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/signinwithtoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers && localVarRequestOptions.headers['Content-Type'] === 'application/json');
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openesLoginForm: async (username?: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, configuration?.basePath);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (username !== undefined) {
                localVarFormParams.set('username', username as any);
            }

            if (password !== undefined) {
                localVarFormParams.set('password', password as any);
            }
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyData} [body] Data sent from openes to start a questionaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyDataAndReturnAMasterToken: async (body?: CompanyData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/startCit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, configuration?.basePath);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers && localVarRequestOptions.headers['Content-Type'] === 'application/json');
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Answers} body Post the data of the first scope question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScopeCategory: async (body: Answers, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling postScopeCategory.');
            }
            const localVarPath = `/api/sendAnswers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.

            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';


            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers && localVarRequestOptions.headers['Content-Type'] === 'application/json');
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RevenueResponse} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRevenue: async (body?: RevenueResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sendRevenue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers && localVarRequestOptions.headers['Content-Type'] === 'application/json');
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubClusterResponse} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSubcluster: async (body?: SubClusterResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sendSubCluster`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || (localVarRequestOptions.headers && localVarRequestOptions.headers['Content-Type'] === 'application/json');
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarPath,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteRequest} [body] Delete the company data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTheCompanyRecords(body?: DeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteTheCompanyRecords(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Given all the answer sent to the backend generate the final report with all the emissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateReport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).generateReport(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
             
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<CompanyAnagrafica>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * download for a year specified
         * @param {string} year 
         * @param {string} language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportPDF(year: string, language:string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).downloadReportPDF(year,language, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
            
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * download for a year specified
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadManuale(language:string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).downloadManuale(language, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
               
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * download for a year specified
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadQuestionario(language:string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).downloadQuestionario(language, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the question for the given scope
         * @param {string} scopeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestion(scopeType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ScopeQuestionaire>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getQuestion(scopeType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the report for the given year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportForYear(reportYear: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Report>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getReportForYear(reportYear, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savereportForm(file?: Blob, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).savereportForm(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */

        async getSubClusters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SubClusterRequest>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getSubClusters(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the token for the current questio
         * @param {SigninwithtokenBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(body?: SigninwithtokenBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Token>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getToken(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openesLoginForm(username?: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).openesLoginForm(username, password, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CompanyData} [body] Data sent from openes to start a questionaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyDataAndReturnAMasterToken(body?: CompanyData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).postCompanyDataAndReturnAMasterToken(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Answers} body Post the data of the first scope question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postScopeCategory(body: Answers, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).postScopeCategory(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RevenueResponse} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRevenue(body?: RevenueResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).sendRevenue(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SubClusterResponse} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSubcluster(body?: SubClusterResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).sendSubcluster(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {DeleteRequest} [body] Delete the company data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTheCompanyRecords(body?: DeleteRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return DefaultApiFp(configuration).deleteTheCompanyRecords(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Given all the answer sent to the backend generate the final report with all the emissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateReport(options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return DefaultApiFp(configuration).generateReport(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: AxiosRequestConfig): Promise<AxiosResponse<CompanyAnagrafica>> {
            return DefaultApiFp(configuration).getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * download for a year specified
         * @param {string} year 
         * @param {string} language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportPDF(year: string, language:string, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return DefaultApiFp(configuration).downloadReportPDF(year, language, options).then((request) => request(axios, basePath));
        },
        /**
         * download for a year specified
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadManuale(language:string, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return DefaultApiFp(configuration).downloadManuale(language, options).then((request) => request(axios, basePath));
        },
        /**
         * download for a year specified
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadQuestionario(language:string, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return DefaultApiFp(configuration).downloadQuestionario(language, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the question for the given scope
         * @param {string} scopeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestion(scopeType: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ScopeQuestionaire>> {
            return DefaultApiFp(configuration).getQuestion(scopeType, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the report for the given year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportForYear(reportYear: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Report>> {
            return DefaultApiFp(configuration).getReportForYear(reportYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savereportForm(file?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return DefaultApiFp(configuration).savereportForm(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubClusters(options?: AxiosRequestConfig): Promise<AxiosResponse<SubClusterRequest>> {
            return DefaultApiFp(configuration).getSubClusters(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the token for the current questio
         * @param {SigninwithtokenBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(body?: SigninwithtokenBody, options?: AxiosRequestConfig): Promise<AxiosResponse<Token>> {
            return DefaultApiFp(configuration).getToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openesLoginForm(username?: string, password?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return DefaultApiFp(configuration).openesLoginForm(username, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyData} [body] Data sent from openes to start a questionaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyDataAndReturnAMasterToken(body?: CompanyData, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return DefaultApiFp(configuration).postCompanyDataAndReturnAMasterToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Answers} body Post the data of the first scope question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postScopeCategory(body: Answers, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return DefaultApiFp(configuration).postScopeCategory(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RevenueResponse} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRevenue(body?: RevenueResponse, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return DefaultApiFp(configuration).sendRevenue(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubClusterResponse} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSubcluster(body?: SubClusterResponse, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return DefaultApiFp(configuration).sendSubcluster(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {DeleteRequest} [body] Delete the company data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async deleteTheCompanyRecords(body?: DeleteRequest, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
        try {
            const response = await DefaultApiFp(this.configuration).deleteTheCompanyRecords(body, options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * Given all the answer sent to the backend generate the final report with all the emissions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async generateReport(options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
        try {
            const response = await DefaultApiFp(this.configuration).generateReport(options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async getProfile(options?: AxiosRequestConfig): Promise<AxiosResponse<CompanyAnagrafica>> {
        try {
            const response = await DefaultApiFp(this.configuration).getProfile(options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * download for a year specified
     * @param {string} year 
     * @param {string} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async downloadReportPDF(year: string, language:string,  options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
        try {
            const response = await DefaultApiFp(this.configuration).downloadReportPDF(year, language, options);
            return response(this.axios, this.basePath);
        }
        catch (error) {
            throw error;
        }
    }
    /**
     * download for a year specified
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async downloadManuale(language:string,  options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
        try {
            const response = await DefaultApiFp(this.configuration).downloadManuale(language, options);
            return response(this.axios, this.basePath);
        }
        catch (error) {
            throw error;
        }
    }
    /**
     * download for a year specified
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async downloadQuestionario(language:string,  options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
        try {
            const response = await DefaultApiFp(this.configuration).downloadQuestionario(language, options);
            return response(this.axios, this.basePath);
        }
        catch (error) {
            throw error;
        }
    }

    /**
     * Get the question for the given scope
     * @param {string} scopeType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async getQuestion(scopeType: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ScopeQuestionaire>> {
        try {
            const response = await DefaultApiFp(this.configuration).getQuestion(scopeType, options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * Get the report for the given year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async getReportForYear(reportYear: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Report>> {
        try {
            const response = await DefaultApiFp(this.configuration).getReportForYear(reportYear, options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async getSubClusters(options?: AxiosRequestConfig): Promise<AxiosResponse<SubClusterRequest>> {
        try {
            const response = await DefaultApiFp(this.configuration).getSubClusters(options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * Get the token for the current questio
     * @param {SigninwithtokenBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async getToken(body?: SigninwithtokenBody, options?: AxiosRequestConfig): Promise<AxiosResponse<Token>> {
        try {
            const response = await DefaultApiFp(this.configuration).getToken(body, options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * 
     * @param {string} [username] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */

    public async openesLoginForm(username?: string, password?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
        try {
            const response = await DefaultApiFp(this.configuration).openesLoginForm(username, password, options)
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * 
     * @param {CompanyData} [body] Data sent from openes to start a questionaire
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async postCompanyDataAndReturnAMasterToken(body?: CompanyData, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
        try {
            const response = await DefaultApiFp(this.configuration).postCompanyDataAndReturnAMasterToken(body, options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * 
     * @param {Answers} body Post the data of the first scope question
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async postScopeCategory(body: Answers, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
        try {
            const response = await DefaultApiFp(this.configuration).postScopeCategory(body, options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * 
     * @param {RevenueResponse} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async sendRevenue(body?: RevenueResponse, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
        try {
            const response = await DefaultApiFp(this.configuration).sendRevenue(body, options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * 
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async savereportForm(file?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
        try {
            const response = await DefaultApiFp(this.configuration).savereportForm(file, options)
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
    /**
     * 
     * @param {SubClusterResponse} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async sendSubcluster(body?: SubClusterResponse, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
        try {
            const response = await DefaultApiFp(this.configuration).sendSubcluster(body, options);
            return response(this.axios, this.basePath);
        } catch (error) {
            throw error
        }
    }
}
