import React, { useEffect, useState } from "react";
import { Grid, Button, Divider, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputAnswer, Question } from "../../Integration/models";
import { CustomWidthTooltip } from "../../styles/Customtooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface props {

    textEng: string | undefined;
    textIta: string | undefined;
    scopeType: string;
    currentLanguage: string,

}

enum Tipo {
    S1 = "scopeTitle1",
    M1 = "scopeTitle2",
    F1 = "scopeTitle3",
    R1 = "scopeTitle4",
    E1 = "scopeTitle5",
    E2 = "scopeTitle6",
}

function convertStringToTipo(input: string): string {
    switch (input) {
        case "S1":
            return Tipo.S1;
        case "M1":
            return Tipo.M1;
        case "F1":
            return Tipo.F1;
        case "R1":
            return Tipo.R1;
        case "E1":
            return Tipo.E1;
        case "E2":
            return Tipo.E2;
        default:
            return " ";
    }
}
const HeadBox: React.FC<props> = ({ scopeType, textEng, textIta, currentLanguage }) => {

    const { i18n, t } = useTranslation();

    return (
        <Paper
            square
            elevation={2}
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                pl: 2,
                bgcolor: 'white',
                paddingBottom: '1rem',
            }}
        >
            <p style={{ color: "#007CC1", fontSize: "1vw", fontWeight: 'bold' }}>{t(convertStringToTipo(scopeType))}</p>
            <Divider style={{ width: "100%", color: "black" }} />

            <p style={{ color: "black", fontSize: "0.8vw", fontStyle:"italic", textAlign: "center", width: "80%" }}>
                {currentLanguage === "en" ? textEng : textIta}
            </p>


        </Paper>
    )
}

export default HeadBox